<template>
	<div class="inner">
		<div class="container">
			<div class="title-btn blf ft24 tc">{{mainVideo.name}}</div>
			<div class="pt50">
				<div class="introinfo-nav tc ft24 el-border-bottom pb30 mb30">
					<el-row>
						<el-col :span="12">
							<div class="el-border-right hover red tr3" @click="handleMoreVideo(code)">
								车型视频
							</div>
						</el-col>
						<el-col :span="12">
							<div class="hover tr3" @click="handleFunVideo(code)">功能视频</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="mb20 pt30 pb20">
				<el-row :gutter="65">
					<el-col :span="12">
						<div class="video-list hover over tr3">
							<div class="video-list-img2 video-list-img">
								<video :src="mainVideo.videoUrl" width="100%" height="100%" controls="controls"></video>
							</div>
							<div class="l16 ft20 mt20 line2 mb30">{{mainVideo.name}}</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!--<div class="tc pb50 mb50">-->
				<!--<el-pagination background layout="prev, pager, next" :total="100">-->
				<!--</el-pagination>-->
			<!--</div>-->
		</div>
	</div>
</template>

<script>
	import { getByInfoCode} from '@/api/index.js'
	export default {
		data() {
			return {
				code: '',
				mainVideo: {
					id: '',
					code: '',
					name: '',
					videoUrl: ''
				},
				mainVideoList: [],
			}
		},
		created(){
			this.code = this.$route.query.code;
			this.getMainVideo(this.$route.query.code)
		},
		methods: {
			getMainVideo(code){
				getByInfoCode(code).then(res=>{
					if(res.code === 200){
						this.mainVideo = {
							id: res.data.id,
							code: res.data.code,
							name: res.data.name,
							videoUrl: res.data.mainVideo,
						}

						// if(res.data.videoUrl){
						// 	let temVideoArr =[]
						// 	let temVideo = res.data.videoUrl.split(',')
						// 	temVideo.forEach(item => {
						// 		let itemVideo = item.substr(item.indexOf('/') + 1)
						// 		temVideoArr.push({
						// 			name: itemVideo.substring(0,itemVideo.indexOf(".")),
						// 			url: item
						// 		})
						// 	})
						// 	this.mainVideoList = temVideoArr
						// }
					}
				})
			},
			/** 跳转到更多视频 ***/
			handleMoreVideo(code){
				this.$router.push({
					path: '/carType-video-list',
					query:{
						code:code.trim()
					}
				})
			},

			/** 功能视频 **/
			handleFunVideo(code){
				this.$router.push({
					path: '/carType-GNvideo-list',
					query:{
						code:code.trim()
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.inner{padding-top: 90px;}
	.title-btn {
		height: 70px;
		background-color: #b4232f;
		border-radius: 50px;
		line-height: 70px;
		background-image: url(../assets/btn-bg.png);
		background-position: center;
		background-size: cover;
		margin-bottom: 50px;
	}

	.details-li img {
		display: inline-block;
		line-height: 1.6;
		vertical-align: baseline;
	}

	.video-list-img {
		width: 100%;
		background-color: #ddd;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
		overflow: hidden;
	}
	.video-list-img2{
		width: 100%;
		background-color: #ddd;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
		height:374px ;
		overflow: hidden;
	}
</style>

